export const environment = {
  production: true,
  apiUrl: 'https://test-api.shogol.sa',
  googleMapKey: 'AIzaSyDx5CBk4DUW-nvCVnHAc-Jy0CIec0SKhR4',
  firebase: {
    apiKey: "AIzaSyAMj1LZ0s4PfZlXcq-DdRWSyxdYHrX9Emg",
    authDomain: "sho8l-96261.firebaseapp.com",
    databaseURL: "https://sho8l-96261-default-rtdb.firebaseio.com",
    projectId: "sho8l-96261",
    storageBucket: "sho8l-96261.appspot.com",
    messagingSenderId: "879215757540",
    appId: "1:879215757540:web:8d79b23a2afd3b87e244f1",
    measurementId: "G-V5294RZD1R"
  }
};

